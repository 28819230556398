<template>
    <v-container>

        <v-row justify="center">
            <v-dialog v-model="dialog" width="1000px">
                <v-card>
                    <v-card-text style="text-align: justify;">
                        <div>
                            <v-btn icon @click="dialog = false" style="float: right;">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>

                            <br>
                            <br>


                            <div style="background: #002C77; border-radius: 9px; text-align: center;">
                                <div style="padding: 2%;">
                                    <label style="font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 22px; line-height: 18px; letter-spacing: 0.02em;
                        color: white; flex: none; order: 1; flex-grow: 0;">Terminos y condiciones del nuevo seguro
                                        Oncológico 2.0</label>
                                </div>


                            </div>


                            <br>
                            <br>


                            <div style="padding-left: 9%; ">
                                <label class=" encabezado"> • {{ info[1][indexInfo] }} Reembolso Gastos médicos
                                    tratamiento Oncológico {{ info[0][indexInfo] }}.</label>
                            </div>


                            <br>
                            <div style="padding-left: 9%; padding-right: 7%; margin-bottom: 10px;">
                                <label class="texto">
                                    En caso de que te diagnostiquen algún tipo de cáncer, podrás iniciar el tratamiento
                                    en la Red de Salud UC CHRISTUS donde reembolsaremos
                                    <label class="textoAzul"> hasta el {{ info[1][indexInfo] }} de los gastos del
                                        tratamiento</label> por
                                    <label class="textoAzul">hasta UF {{ info[3][indexInfo] }} (más de 1.000 millones de
                                        pesos) </label>
                                    n el caso que tengas menos de 69 años al momento de tu diagnóstico y
                                    <label class="textoAzul">UF {{ info[4][indexInfo] }} (más de 180 millones de pesos)
                                        en caso que tengas 70 o más años </label>
                                    al momento de tu diagnóstico.
                                    <br> </label>
                            </div>
                            <div style="text-align: center; ">
                                <label class="texto"> Si quieres revisar más en detalle los <br> gastos que cubre
                                </label><a :href="document" target="_blank"> <br>
                                    <div
                                        style="border: solid 0.75px; margin-left: 43%; margin-right: 43%; border-radius: 5px;  ">
                                        haz clic
                                        aquí</div>
                                </a>
                            </div>

                            <br>
                            <br>

                            <div style="padding-left: 9%; ">
                                <label class="encabezado"> • Indemnización Libre disposición ante diagnóstico
                                    positivo.</label>
                            </div>

                            <br>

                            <div style="padding-left: 9%; padding-right: 7%;">
                                <label class="texto">En caso de que te diagnostiquen a tí o algún integrante de tu
                                    familia que esté contigo en el seguro algún tipo de cáncer, podrás solicitar a la
                                    compañía
                                    <label class="textoAzul">UF {{ info[5][indexInfo] }} de indemnización</label> en
                                    caso que el asegurado tenga menos de 69 años al momento de su diagnóstico o
                                    <label class="textoAzul"> UF {{ info[6][indexInfo] }} si tienen más de 70 años al
                                        momento de su diagnóstico</label>, para que lo usen en lo que quieran, como
                                    alojamientos y traslados para el tratamiento o comprarse lo que necesiten.
                                    <label class="textoAzul">
                                        Sin preguntas</label>.</label>
                            </div>

                            <br>

                            <div style="padding-left: 9%; padding-right: 7%;">
                                <label class="texto">Esto pueden solicitarlo con la confirmación de <label
                                        class="textoAzul">UC
                                        CHRISTUS</label> de
                                    contratación y hasta de un diagnóstico positivo de cáncer.</label>
                            </div>
                            <br>
                            <br>

                            <div style=" padding-left: 9%;">
                                <label class=" encabezado"> • Agrega a integrantes de tu familia sin cambiar el precio
                                    del
                                    seguro.</label>
                            </div>



                            <br>

                            <div style="padding-left: 9%; padding-right: 7%;">
                                <label class="texto">El plan {{ info[0][indexInfo] }} permite que agregues a tu <label
                                        class="textoAzul">cónyuge/pareja/conviviente </label>
                                    y a <label class="textoAzul">todos </label>tus y sus <label class="textoAzul">hijos
                                        e hijas
                                    </label>(hasta
                                    24 años de edad) por el <label class="textoAzul">mismo valor del plan</label>, y
                                    cada uno con la
                                    protección de UF 30.000 de tratamiento y con las UF {{ info[5][indexInfo] }} de
                                    indemnización ante
                                    diagnostico positivo. </label>
                            </div>

                            <br>
                            <br>

                            <div style="padding-left: 9%;">
                                <label class=" encabezado"> • Protección desde el día que renuevas</label>
                            </div>


                            <br>
                            <div style="padding-left: 9%; padding-right: 7%;">
                                <label class="texto">La protección de este seguro comienza desde el <label
                                        class="textoAzul">día que
                                        renuevas</label>.
                                    Olvídate de carencias o semanas para que comience a protegerte. </label>
                            </div>



                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn style="box-sizing: border-box; padding: 12px 24px; gap: 10px; background: #FFB600; border: 2px solid #FFB600;
                    border-radius: 8px; margin-top: -6%; font-family: Helvetica; font-style: normal; font-weight: 700; font-size: 14px; line-height: 17px;
                    letter-spacing: 0.02em; color: white; flex: none; order: 0; flex-grow: 0; margin:auto;"
                            elevation="0" text @click="dialog = false">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <br>

        <!-- <NavBar :step="6" :userData="userData" :beneficiaries="beneficiaries"></NavBar> -->

        <Titulo></Titulo>

        <br>
        <br>
        <br v-if="screen < 800">

        <div v-show="showTokuIframe">

            <div id="toku-container">

            </div>

        </div>

        <div v-if="loadingToku == true" class="text-center" style="margin-top:5%">
            <!-- <div v-if="loadingFinal == true" class="text-center" style="margin-top:5%"> -->

            <v-progress-circular :size="300" color="primary" indeterminate></v-progress-circular>

            <br>
            <br>

            {{ cont }}

        </div>

        <div v-else>

            <div v-if="!showTokuIframe" style="max-width: 500px; margin: auto;">

                <h3 class="text-center labelPaso">
                    ¡{{ userData.name }}, VAMOS A ACTUALIZAR TU SEGURO
                </h3>

                <br>

                <h4 class="text-center labelSubpaso">
                    Antes de actualizar, te mostramos un resumen de tu nuevo seguro oncológico
                </h4>

                <v-row>

                    <v-col>

                        <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" style="margin-left: 10%"
                            height="600px" max-width="100%">
                            <v-card-text class=" font-weight-medium mt-12 text-center text-subtitle-1 color:primary">
                            </v-card-text>

                            <v-col>
                                <label class="labelEtiquetaResumen">Contratante: </label>

                                <label class="labelEtiqueta">{{ userData.name }} {{ userData.surname }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Rut: </label>

                                <label class="labelEtiqueta">{{ userData.rut }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Teléfono: </label>

                                <label class="labelEtiqueta">{{ userData.phoneNumber }}</label>
                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Email: </label>

                                <label class="labelEtiqueta">{{ userData.email }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Tipo de Plan: </label>

                                <label class="labelEtiqueta">{{ userData.typePlan }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Previsión de Salud: </label>

                                <label class="labelEtiqueta">{{ userData.salud }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Declaración de Salud: </label>

                                <label class="labelEtiqueta">Ok</label>

                                <br>
                                <br>

                                <v-col style="text-align: center; margin-left:50px;">
                                    <label><b>El nuevo precio de tu seguro es:</b></label>
                                </v-col>

                                <div v-if="loadingPlans == true" :class="rectangulo" style="text-align:center;">

                                    <label class="labelPrecioPlan">{{ formatPrice(userData.primaCLP)
                                        }}</label>

                                    <label class="labelPrecioUf"> / UF {{ userData.prima.toFixed(2) }}
                                        mensual</label>

                                </div>

                                <div class="lineaIzquierda"></div>

                                <div class="lineaDerecha"></div>

                                <br>
                                <br>

                                <h3 class="text-center labelFecha">*De acuerdo al valor UF del día {{ fechaActual }}
                                </h3>
                                <v-col style="text-align: center; margin-left:50px;">
                                    <label><b>Promoción de migración:</b></label>
                                    <br>
                                    <label class="perroperuano"><b>¡Primer mes costo $0!</b></label>
                                </v-col>
                            </v-col>

                        </v-card>

                        <v-form ref="form" v-model="valid" max-witdh="300px">

                            <v-hover>
                                <v-checkbox :class="vista" :rules="conditionsRules" :color="stepperColor" required>
                                    <template v-slot:label>
                                        <p :class="terminos">Acepto los
                                            <b>
                                                <a style="color:#2D387C" @click="dialog = true">Términos y
                                                    Condiciones</a>
                                            </b>
                                        </p>
                                    </template>
                                </v-checkbox>
                            </v-hover>


                        </v-form>

                        <div class="button-container">
                            <v-btn v-if="!pagoVerify"
                                style="background-color: #4F74E3; color: white; border-radius: 12px; width: 300px; height: 120%; margin-left: 5%;"
                                @click="validate()">
                                ACTUALIZAR MEDIO DE PAGO
                            </v-btn>
                            <v-btn v-else
                                style="background-color: #4F74E3; color: white; border-radius: 12px; width: 300px; height: 120%; margin-left: 5%;"
                                disabled @click="validate()">
                                ACTUALIZAR MEDIO DE PAGO
                            </v-btn>
                        </div>

                    </v-col>

                </v-row>

            </div>

            <br>
        </div>

    </v-container>
</template>

<script>
import ShieldService from "../services/shield.service.js";
import Titulo from "../components/Titulo.vue";
// import NavBar from "@/components/NavBar.vue";
const moment = require('moment');
import swal from 'sweetalert';
// import { info } from "node-sass";

export default {
    data() {
        return {
            personId: '',
            showTokuIframe: false,
            toku: null,
            terminos: null,
            dialog: false,
            name: "Test",
            fechaActual: null,
            cont: "Cargando.  ",
            loading: false,
            loadingToku: false,
            loadingFinal: false,
            loadingPlans: false,
            screen: window.screen.width,
            formatter: new Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP",
            }),
            stepperColor: '#FFB600',
            planData: null,
            lengthBenef: null,
            beneficiaries: [],
            stepId: null,
            edadMayor: null,
            ufPrice: null,
            pagoVerify: false,
            idCotizacion: null,
            userData: {
                name: null,
                surname: null,
                rut: null,
                email: null,
                phoneNumber: null,
                couponId: null,
                salud: null,
                typePlan: null,
                birthdate: null,
                prima: null,
                primaCLP: null,
            },
            conditionsRules: [
                (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
            ],
            indexInfo: 0,
            info: [['Isapre Individual', 'Isapre Familiar', 'Fonasa Individual', 'Fonasa Familiar'], ['100%', '100%', '100%', '100%'], ['50', '50', '50', '50'], ['30.000', '30.000', '30.000', '30.000'], ['5.000', '5.000', '5.000', '5.000'], ['50', '50', '50', '50'], ['10', '10', '10', '10'], ['0', '30.000', '0', '30.000'], ['0', '50', '0', '50']],
        }
    },
    // components: { Titulo, NavBar },
    components: { Titulo },
    async mounted() {

        const query = this.$route.query;

        //llamar aca al metodo getCotizacion
        try {

            if (query.cotizacion) {
                this.idCotizacion = query.cotizacion;

                this.getUFPrice();

                const migratesData = await this.getMigrates();

                if (migratesData.flagTransactionComplete && migratesData.flagTransactionComplete == 'OK') {
                    swal({
                        title: "¡Pago actualizado!",
                        text: "Usted ya ha realizado el proceso de actualización, muchas gracias.",
                        icon: "info",
                        button: "Ok",
                    });
                    this.pagoVerify = true;
                    // }).then(() => {
                    //     window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
                    // });
                }

                document.body.scrollTop = document.documentElement.scrollTop = 0;

                this.userData.name = migratesData.name;
                //Setear en nueva variable
                localStorage.setItem('nameFinishMigracion', this.userData.email) //Unico solo para finish
                this.userData.surname = migratesData.surname;
                this.userData.rut = migratesData.rut;
                this.userData.phoneNumber = migratesData.telefono
                this.userData.email = migratesData.email;
                //Setear en nueva variable
                localStorage.setItem('emailFinishMigracion', this.userData.email) //Unico solo para finish
                this.userData.typePlan = migratesData.tipoPlan;
                this.userData.salud = migratesData.tipoCobertura;
                this.userData.birthdate = migratesData.birthDate;
                this.userData.prima = migratesData.prima;

                this.calculateOld();
                this.calculatePrimaCLP();

                this.loadingPlans = false;
                console.log(this.userData)
                console.log(this.edadMayor)
                this.planData = await ShieldService.getPlans(this.userData.salud, this.userData.typePlan, this.edadMayor, this.userData.couponId);
                this.loadingPlans = true;

                if (this.planData[0].cantBenf == 'Plan Individual Fonasa') {
                    this.indexInfo = 2;
                } else if (this.planData[0].cantBenf == 'Plan Familiar Fonasa') {
                    this.indexInfo = 3;
                } else if (this.planData[0].cantBenf == 'Plan Individual Isapre') {
                    this.indexInfo = 0;
                } else if (this.planData[0].cantBenf == 'Plan Familiar Isapre') {
                    this.indexInfo = 1;
                }

                this.calcularFecha();

            } else {
                swal({
                    title: "Error!",
                    text: "No se específica cotización!",
                    icon: "info",
                    button: "OK",
                });
            }

            if (this.screen > 800) {
                this.vista = 'test'
                this.rectangulo = 'rectangulo'
                this.terminos = 'terminos'
            } else {
                this.vista = 'test2'
                this.rectangulo = 'rectangulo2'
                this.terminos = 'terminos2'
            }
        } catch (error) {
            // swal({
            //     title: "Error!",
            //     text: "Ocurrió un error en el proceso!",
            //     icon: "info",
            //     button: "OK",
            // });
            console.log(error)
        }


    },
    methods: {
        async getUFPrice() {
            var consultUf = await ShieldService.getUFPrice();
            if (consultUf.status == 'ok') {
                this.ufPrice = consultUf.data;
            }
        },
        calculateOld() {
            const fechaActual = moment(new Date());
            const dateNacimiento = moment(this.userData.birthdate, "MM-DD-YYYY");
            const timesDiff = fechaActual.diff(dateNacimiento, 'years');
            this.edadMayor = timesDiff;
        },
        async calculatePrimaCLP() {
            this.userData.primaCLP = Math.round(this.ufPrice * this.userData.prima);
            if (this.userData.primaCLP == 0) {
                location.href = window.location.href;
            }
        },
        async getMigrates() {
            const shieldRes = await ShieldService.getMigrates(this.idCotizacion);

            return shieldRes;
        },
        calcularFecha() {
            var fecha = new Date().toISOString().substr(0, 10).split('-')
            this.fechaActual = fecha[2] + '/' + fecha[1] + '/' + fecha[0]
        },
        formatPrice(number) {
            return this.formatter.format(number);
        },
        back() {
            this.$emit("back");
        },
        async validate() {
            if (this.$refs.form.validate()) {

                // Conectar con SHIELD
                this.loadingToku = true;
                this.loading = true;
                this.loadingFinal = true;

                this.cont = "Estamos Procesando tu Solicitud . . ."
                try {
                    
                    const res = await ShieldService.updateMigrates(
                        this.idCotizacion,
                        this.planData,
                    );

                    // console.log("response sheild: ", res)

                    window.location.href = res;

                    this.loadingToku = true;
                    this.loadingToku = false;

                } catch (error) {
                    this.loadingFinal = false;
                    this.loading = false;
                    this.$router.push({ path: '/error' })
                }
            }
        },
    }
}
</script>

<style lang="scss">
.toku-frame {
    border: none;
}

// html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div div div.row div.col form.v-form div.v-input.theme--light.v-input--selection-controls.v-input--checkbox div.v-input__control div.v-input__slot {
//     width: 90%;
//     margin: auto;
// }

// html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div div div.row div.col form.v-form div.v-input.v-input--has-state.theme--light.v-input--selection-controls.v-input--checkbox.error--text div.v-input__control div.v-messages.theme--light.error--text div.v-messages__wrapper div.v-messages__message{
//     text-align: center !important;
// }

.labelFecha {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #232323;
    opacity: 0.75;
    margin-left: 20%;
}

.labelPrecioPlan {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #2D387C;
}

.labelPrecioUf {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #2D387C;
    opacity: 0.8;
}

.botonCondiciones {
    box-sizing: border-box;
    padding: 12px 24px;
    gap: 10px;
    background: #FFB600;
    border: 2px solid #FFB600;
    border-radius: 8px;
    margin-top: -6%;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
}

.perroperuano {
    font-size: 20px;
    color: #4F74E3;
}

.labelPaso {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #FFB600;
    text-transform: uppercase;
}

.labelSubpaso {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #2D387C;
}

.labelEtiquetaResumen {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: black;
}

.labelEtiqueta {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: black;
}

.terminos {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
}

.terminos2 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
}

.test {
    margin-left: 25%;
    margin-top: 1%;
}

.test2 {
    margin-left: 15%;
    margin-top: 2%;
}

.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.rectangulo {
    box-sizing: border-box;
    position: absolute;
    height: 30px;
    width: 400px;
    border: 2px solid #2D387C1f;
    border-radius: 5px;
    margin-left: 25%;
}

.linea {
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    border-top: 2px solid #2D387C1f;
    width: calc(24%);
    height: 0;
    background: #2D387C;
    opacity: 0.15;
}

.lineaIzquierda {
    left: 0;
    width: 122px;
}

.lineaDerecha {
    right: 0;
    width: 120px;
}

.rectangulo2 {
    box-sizing: border-box;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 431px;
    height: 42px;
    width: 250px;
    border: 2px solid #2D387C1f;
    border-radius: 5px;
}

.step-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-weight: 500;
        text-align: center;
        color: #464e71;
        margin-bottom: 2rem;
    }


    h2 {
        font-weight: 500;
        color: #464e71;
        margin-bottom: 1rem;
    }

    p {
        text-align: center;
    }

    .back-container {
        width: 100%;
        align-items: flex-start;

        .back-button {


            &:hover {
                opacity: 1;
            }
        }
    }

    .button-container {
        text-align: center;
    }

    .forecast-selector {
        text-transform: uppercase;
        font-weight: 600;
        color: #464e71;
    }
}
</style>
