<template>
  <v-container>

    <NavBar v-if="loadMounted == true" :step="5" :typePlan="userData.typePlan"></NavBar>

    <Titulo></Titulo>

    <br>
    <br v-if="screen < 800">

    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">
      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="5">
          <v-form ref="form" v-model="valid">
            <v-container style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
              <div class="form-container">

                <v-col :class="vista">

                  <br>
                  <br>

                  <h3 class="text-center labelIngresar">
                    Ingresa tus datos
                  </h3>

                  <h4 class="text-center labelSubingresar">
                    Son necesarios para completar la póliza del seguro
                  </h4>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.name" :rules="nameRules" label="Nombre" required
                    @change="change()" maxlength="51" filled solo></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.surname" :rules="surnameRules" label="Apellido"
                    @change="change()" maxlength="51" required filled solo></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.email" :rules="emailRules" label="Email" required
                    @change="change()" maxlength="51" filled solo></v-text-field>

                  <br>

                  <v-row v-if="screen < 800">

                    <v-col>
                      <v-select class="labelDatesMovil" style="max-width: 90px; height: 48px;" :rules="monthRules"
                        :items="months" v-model="userData.month" :loading="loadingMonths" v-on:change="getDays()"
                        item-text="name" label="Mes" return-object required filled solo></v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesMovil" style="max-width: 90px; height: 48px;" :rules="dayRules"
                        :items="days" v-model="userData.day" :loading="loadingDays"
                        :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object
                        required filled solo>
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesMovil" style="max-width: 90px; height: 48px;" :rules="yearRules"
                        value="2022" :items="years" v-model="userData.year" item-text="name" label="Año" return-object
                        required filled solo @change="changeBisiesto()"></v-select>
                    </v-col>

                  </v-row>

                  <v-row v-else>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="monthRules"
                        :items="months" v-model="userData.month" :loading="loadingMonths" v-on:change="getDays()"
                        item-text="name" label="Mes" return-object required filled solo></v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="dayRules"
                        :items="days" v-model="userData.day" :loading="loadingDays"
                        :disabled="loadingDays || userData.month === null" item-text="name" label="Día" return-object
                        required filled solo>
                      </v-select>
                    </v-col>

                    <v-col>
                      <v-select class="labelDatesdesktop" style="max-width: 160px; height: 48px;" :rules="yearRules"
                        value="2022" :items="years" v-model="userData.year" item-text="name" label="Año" return-object
                        required filled solo @change="changeBisiesto()"></v-select>
                    </v-col>

                  </v-row>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.phoneNumber" :rules="phoneRules" prefix="+569"
                    @change="change()" :counter="8" maxlength="8" label="Teléfono" required filled solo></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model.lazy="newRut" :rules="rutRules(newRut)" @change="change()"
                    label="Ingresa tu RUT" required filled solo @input="changeRut"></v-text-field>

                  <br>

                  <v-text-field class="labelsDatos" v-model="userData.serialNumber" :rules="serialNumberRules"
                    @change="change()" maxlength="10" label="Ingresa el número de Documento / Serie" required filled
                    solo>
                  </v-text-field>

                  <br>

                  <v-select v-if="userData.salud == 'Isapre'" class="labelsDatos" :items="isapreTypes"
                    v-model="userData.typeIsapre" label="Tipo Isapre" :rules="typeIsapreRules" return-object required
                    filled solo></v-select>

                  <br v-if="userData.salud == 'Isapre'">

                  <v-text-field class="labelsDatos" v-model="userData.address" :rules="addressRules" maxlength="51"
                    @change="change()" label="Ingresar nombre de calle" required filled solo></v-text-field>

                  <br>

                  <div class="number-address">
                    <v-text-field class="labelsDatos-address" v-model="userData.addressNumber"
                      :rules="NumberRulesAddress" maxlength="51" :label="addressNumber" required filled solo
                      @change="change()"></v-text-field>
                    <v-text-field class="labelsDatos-address" v-model="userData.addressDepto" maxlength="51"
                      :label="addressDepto" required filled solo @change="change()"></v-text-field>
                  </div>

                  <br>

                  <v-select class="labelsDatos" :items="regions" v-model="userData.region" :rules="regionRules"
                    @change="change()" :loading="loadingRegions" v-on:change="getCommunes()" item-text="name"
                    label="Selecciona tu región de residencia" required return-object filled solo></v-select>

                  <br>

                  <v-select class="labelsDatos" :items="communes" v-model="userData.commune" :rules="communeRules"
                    @change="change()" :loading="loadingCommunes"
                    :disabled="loadingCommunes || userData.region === null" item-text="name"
                    label="Selecciona tu comuna de residencia" required return-object filled solo>
                  </v-select>

                  <br>
                  <br>

                  <div class="button-container">
                    <v-btn
                      style="background-color: #2D387C; color: white; border-radius: 12px; width: 100%; height: 120%;"
                      @click="validate()">
                      Continuar
                    </v-btn>

                    <br>
                    <br>

                    <v-btn text @click="back()"> Atras </v-btn>

                  </div>

                </v-col>

              </div>
            </v-container>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>

import CountryService from "@/services/country.service.js";
import Titulo from "../components/Titulo.vue";
import RutUtils from "@/utils/rut.utils.js";
import NavBar from "@/components/NavBar.vue";

import ShieldService from "../services/shield.service";
import dateService from "@/services/date.service.js";
import yearService from "@/services/year.service.js";

import swal from 'sweetalert';
const moment = require('moment');
import CryptoJS from 'crypto-js';

export default {
  name: "Step4",
  components: { Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    loadMounted: false,
    screen: window.screen.width,
    stepperColor: '#4F74E3',
    stepId: null,
    TipoPlan: null,
    e1: 5,
    valid: false,
    // isapreTypes: ["Banmédica", "Chuquicamata", "Colmena", "Consalud", "Cruz Blanca", "Cruz del Norte", "Nueva Masvida", "Fundación", "Fusat", "Río Blanco", "San Lorenzo Ltda.", "Vida Tres"],
    isapreTypes: ["Banmédica", "Chuquicamata", "Colmena", "Consalud", "Cruz Blanca", "Nueva Masvida", "Fundación", "Fusat", "San Lorenzo Ltda.", "Vida Tres", "Santa María", "Más Salud"],
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      typeIsapre: null,
      region: null,
      commune: null,
      comuneCode: null,
      regionCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      stepId: null,
      old: null,
      dates: null,
      salud: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null,
      typePlan: null,
      addressNumber: null,
      addressDepto: null,
      sonExist: false,
      arrayTypesBenef: [],
      oldAux: null,
      day: null,
      month: null,
      year: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'create',
    },
    planData: {
      planOutDesc: null,
      setDesc: false,
      valueDesc: 0,
      couponId: null,
      plan: null,
    },
    benefData: [
      {
        validRut: false,
        tipo: null,
        edad: null,
        name: '',
        surname: '',
        rut: '',
        days: ['Selecciona el mes'],
        months: null,
        years: null,
        date: null,
        newRut: '',
        panel: 0,
        year: null,
        month: null,
        day: null,
      }
    ],
    rutIsDuplicate: false,
    months: [],
    days: ["Selecciona el mes"],
    yars: [],
    month: null,
    day: null,
    yar: null,
    activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
    addressRules: [
      (v) => !!v || "Debes ingresar nombre de calle",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    typeIsapreRules: [(v) => !!v || "Debes ingresar tu tipo de ISAPRE",],
    regionRules: [(v) => !!v || "Debes ingresar tu región",],
    communeRules: [(v) => !!v || "Debes ingresar tu comuna",],
    serialNumberRules: [
      (v) => !!v || "El número de serie es requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres"
    ],
    NumberRulesAddress: [
      (v) => !!v || "N° de calle requerido",
      (v) => (!!v && v.length <= 9) || "Debe ingresar máximo 9 caracteres"
    ],
    emailRules: [
      (v) => !!v || "El correo electrónico es requerido",
      (v) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(v) || "Ingrese un correo válido",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    phoneRules: [
      (v) => !!v || "Número telefónico requerido",
      (v) => /^[0-9]*$/.test(v) || "Ingrese solo números",
      (v) => (!!v && v.length == 8) || "Ingrese un número válido",
    ],
    nameRules: [
      (v) => !!v || "El nombre es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    surnameRules: [
      (v) => !!v || "El apellido es requerido",
      (v) => !/^[0-9]*$/.test(v) || "Ingrese solo letras",
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres"
    ],
    monthRules: [
      (v) => !!v || "El mes es requerido",
    ],
    dayRules: [
      (v) => !!v || "El día es requerido",
    ],
    yearRules: [
      (v) => !!v || "El año es requerido",
    ],
    regions: [],
    communes: [],
    newRut: "",

  }),
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.getRegions();
    this.getMonths();
    this.getYears();

    const userDataAux = localStorage.getItem('userData');
    const benefDataAux = localStorage.getItem('benefData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const planDataAux = localStorage.getItem('planData');

    const query = this.$route.query;

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';
      this.planData = abandoned.planData;

      if (abandoned.benefData) {
        this.benefData = abandoned.benefData;
      }

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const planDataLocal = JSON.stringify(this.planData);
      const benefDataLocal = JSON.stringify(this.benefData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();
      const encryptedBenefDataLocal = CryptoJS.AES.encrypt(benefDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('planData', encryptedPlanDataLocal);
      localStorage.setItem('benefData', encryptedBenefDataLocal)

      this.getCommunes();

      if (this.userData.rut) {
        this.newRut = this.userData.rut;
        this.changeRut(this.userData.rut);
      }
      
      if (this.userData.dates) {
        this.getDaysMounted();
      }

    } else {

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);

      this.getCommunes();

      if (this.userData.dates) {
        this.getDaysMounted();
      }
      // console.log("userData: ", this.userData);

      if (benefDataAux) {
        const bytesBenefData = CryptoJS.AES.decrypt(benefDataAux, this.secretKey);
        const decryptedDataPet = bytesBenefData.toString(CryptoJS.enc.Utf8);
        this.benefData = JSON.parse(decryptedDataPet);
        // console.log("benefData: ", this.benefData);
      }
      
      if (this.userData.rut) {
        this.newRut = this.userData.rut;
        this.changeRut(this.userData.rut);
      }

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
      const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
      this.planData = JSON.parse(decryptedDataPlan);
      // console.log("planData: ", this.planData);
    }

    if (this.screen > 800) {
      this.vista = 'vista2'
      this.TipoPlan = 'PlanEscritorio'
      this.addressNumber = 'Ingresa tu número de calle'
      this.addressDepto = 'Ingresa tu número de depto'
    } else {
      this.vista = 'vista'
      this.TipoPlan = 'PlanMovil'
      this.addressNumber = 'Número de calle'
      this.addressDepto = 'Número de depto'
    }

    this.loadMounted = true;
  },
  methods: {
    async changeBisiesto() {
      if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && !this.isBisiesto(this.userData.year.id) && this.days.length == 29) {
        this.userData.day = null;
        this.days = await dateService.getDays(
          this.userData.month.name, false
        );
      } else if (this.userData.month && this.userData.year && this.days && this.userData.month.name == 'Febrero' && this.isBisiesto(this.userData.year.id) && this.days.length == 28) {
        this.days = await dateService.getDays(
          this.userData.month.name, true
        );
      } else {
        this.days = await dateService.getDays(
          this.userData.month.name, null
        );
      }
    },
    isBisiesto(anio) {
      return (anio % 4 === 0 && anio % 100 !== 0) || (anio % 400 === 0);
    },
    async getMonths() {
      this.loadingMonths = true;
      this.months = await dateService.getMonths();
      this.loadingMonths = false;
    },
    async getDays() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.userData.day = null;
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getDaysMounted() {
      if (this.userData.month) {
        this.loadingDays = true;
        this.changeBisiesto();
        this.loadingDays = false;
      }
    },
    async getYears() {
      this.loadingYears = true;
      this.years = await yearService.getYears();
      this.loadingYears = false;
    },
    rutRules(v) {
      return [
        !!v || "El RUT es requerido",
        (v) => (v && v.length >= 11 && v.length <= 12) || "Rut no válido",
        RutUtils.validateRut(v) || "Rut no válido",
        RutUtils.getCondition(v, this.rutIsDuplicate) || "RUT coincide con beneficiario"
      ];
    },
    async getRegions() {
      this.loadingRegions = true;
      this.regions = await CountryService.getRegions();
      this.loadingRegions = false;
    },
    async getCommunes() {
      if (this.userData.region) {
        this.loadingCommunes = true;
        // this.userData.commune = null;
        this.communes = await CountryService.getCommunes(
          this.userData.region.name
        );
        this.loadingCommunes = false;
      }
    },
    changeRut(rut) {
      if (this.userData.typePlan == 'Familiar') {

        const cleanRut = (rut) => rut ? rut.replace(/[.-]/g, '').toLowerCase() : '';

        const rutExists = this.benefData.some(benef => cleanRut(benef.rut) === cleanRut(rut));

        if (rutExists) {
          this.rutIsDuplicate = true;
          swal({
            title: "Atención!",
            text: "Tu RUT no puede coincidir con el de algún beneficiario!",
            icon: "info",
            button: "De acuerdo",
          });
        } else {
          this.rutIsDuplicate = false;
        }
      } else {
        this.rutIsDuplicate = false;
      }
      this.userData.rut = RutUtils.cleanRut(rut);
      this.newRut = RutUtils.formatRut(rut);
      this.userData.rut = this.newRut;
    },
    async validate() {
      if (this.$refs.form.validate() && this.rutIsDuplicate === false) {

        try {

          const fechaActual = new moment(new Date());
          var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
          var dateNacimiento = moment(new Date(newDate));
          var timesDiff = fechaActual.diff(dateNacimiento, 'years');

          if (timesDiff < 18) {
            swal({
              title: "Atención!",
              text: "Para contratar este seguro, usted debe ser mayor de edad!",
              icon: "info",
              button: "De acuerdo",
            });
          } else if (timesDiff != this.userData.old.name) {
            swal({
              title: "Atención!",
              text: "La fecha de nacimiento no concuerda con su edad!",
              icon: "info",
              button: "De acuerdo",
            });
          } else {

            var setVar = false;

            this.userData.dates = newDate;

            if (this.userData.typePlan == 'Familiar') {
              this.userData.sonExist = this.benefData.some(benef => benef.tipo === "Hijo(a)");
              this.userData.arrayTypesBenef = this.benefData.map(item => item.tipo);
            } else {
              this.userData.sonExist = false;
              this.userData.arrayTypesBenef = [];
            }

            this.change();

            const userDataLocal = JSON.stringify(this.userData);
            const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
            localStorage.setItem('userData', encryptedUserDataLocal);

            if (this.activeCampaingUse == 'TRUE') {

              const activeCampaingRes = await ShieldService.getContactActiveCampain(this.userData.email);

              if (activeCampaingRes.data.data.meta.total != '0') {
                this.servicesData.contactID = activeCampaingRes.data.data.contacts[0].id;
                setVar = true;
              } else {
                this.servicesData.contactID = null;
              }

              const shieldRes = await ShieldService.updateContactActiveCampain(this.userData, this.planData, this.servicesData, 'datos contratante');

              this.servicesData.contactID = setVar ? shieldRes.data.data.contact.id : shieldRes.data.data.contacts[0].id;

              const servicesDataLocal = JSON.stringify(this.servicesData);
              const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
              localStorage.setItem('servicesData', encryptedServicesDataLocal);

            }

            this.$router.push({ path: '/og/resumen' })
          }

        } catch (error) {
          this.$router.push({ path: '/og/error' })
        }

      }
    },
    back() {
      if (this.userData.typePlan == 'Individual') {
        this.$router.push({ path: '/og/dps' })
      } else {
        this.$router.push({ path: '/og/datos-beneficiarios' })
      }
    },
    async change() {

      if (this.userData.year && this.userData.month && this.userData.day) {
        const fechaActual = new Date();
        var newDate = this.userData.year.id + "-" + this.userData.month.number + "-" + this.userData.day.id
        var diff = fechaActual.getFullYear() - this.userData.year.id
        if (diff > 18) {
          this.userData.dates = newDate;
        }
      }

      try {
        await ShieldService.newCreateStep(this.userData, null, null, this.servicesData, 'Datos personales');
      } catch (error) {
        console.log("Cart Fail");
      }
    }
  },
};
</script>

<style lang="scss">
.labelIngresar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
  margin-top: -5%;
}

.labelSubingresar {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.labelsDatos {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

.vista {
  max-width: 1000px;
}

.vista2 {
  min-width: 500px;
}

.step-date {
  align-items: center;
  display: flex;
  margin-left: 0rem;
}

.number-address {
  display: flex;
  gap: 10px;
}

.labelsDatos-address {
  box-sizing: border-box;
  height: 57px;
  margin: auto;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 6px;
}

html body div#app.v-application.v-application--is-ltr.theme--light div.v-application--wrap main.v-main div.v-main__wrap div.container div.v-stepper.v-sheet.theme--light.elevation-0 div.v-stepper__items div.v-stepper__content div.v-stepper__wrapper form.v-form div.container div.form-container div.col.vista2 div.v-input.labelsDatos.v-input--is-disabled.theme--light.v-text-field.v-text-field--single-line.v-text-field--solo.v-text-field--filled.v-text-field--is-booted.v-text-field--enclosed.v-select div.v-input__control div.v-text-field__details div.v-messages.theme--light div.v-messages__wrapper div.v-messages__message {
  color: red;
}

html body div.swal-overlay.swal-overlay--show-modal div.swal-modal div.swal-text {
  text-align: center;
}
</style>