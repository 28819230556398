<template>
  <v-container style="margin:auto">

    <NavBar :step="1"></NavBar>

    <Titulo></Titulo>

    <br v-if="screen <= 800">
    <br v-if="screen <= 800">
    <br v-if="screen <= 800">

    <v-stepper v-model="e1" elevation="0">

      <v-stepper-header>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 1" step="1"> Inicio
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 2" step="2"> Plan
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 3" step="3"> Info
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" :complete="e1 > 4" step="4"> DPS
          </v-stepper-step>
        </div>
        <v-divider></v-divider>
        <div class="circle-step2">
          <v-stepper-step style="flex-direction: column;" :color="stepperColor" step="5"> Datos </v-stepper-step>
        </div>
      </v-stepper-header>

      <br v-if="screen > 800">
      <br v-if="screen > 800">

      <v-stepper-items>
        <v-stepper-content step="2">

          <div class="formData">

            <v-form ref="form" v-model="valid" max-witdh="300px" @submit.prevent>

              <br>
              <br>

              <h3 class="text-center labelTituloo">Estos son nuestros planes</h3>

              <h4 class="text-center labelSubtitulo">Elige tu plan</h4>

              <br>

              <v-row>
                &nbsp;&nbsp;&nbsp;
                <v-text-field :class="labelDescuento" v-model="planData.couponId" :rules="couponRules"
                  label="Ingresa aquí tu código de descuento" maxlength="51" solo @blur="deteleSpace()"
                  @keyup.enter="SendCouponID"></v-text-field>

                <v-btn class="botonAplicar" style="background-color: #4F74E3; color: white; width: 70px; height: 50px;"
                  solo @click="SendCouponID">Aplicar
                </v-btn>

              </v-row>

            </v-form>

          </div>

          <!-- <br> -->
          <br>
          
          <div v-if="planData.setDesc" :class="labeltextDesc">
            <label class="descText"> &nbsp;&nbsp;  ¡Descuento del {{this.planData.valueDesc}}% aplicado!</label>
          </div>
          
          <div v-if="screen > 800" class="planContainer">
            <PlanEscritorio v-for=" (plan, indice) in plans" :key="plan._id" :planData="plan" :index="indice" :plansOutDesc="plansOutDesc"
              :userData="userData" :servicesData="servicesData" :planDataAux="planData" :loadingPlans="loadingPlans"
              @next-step="validate" />
          </div>

          <div v-if="screen <= 800" class="planContainer">
            <PlanMovil :planData="plans" :userData="userData" :servicesData="servicesData" :planDataAux="planData" :plansOutDesc="plansOutDesc"
              :loadingPlans="loadingPlans" @next-step="validate" />
          </div>

        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-row style="justify-content: center; margin-top: 1rem">
      <v-btn text @click="back()"> Atras </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import PlanEscritorio from "@/components/PlanEscritorio.vue";
import PlanMovil from "@/components/PlanMovil.vue";
import ShieldService from "../services/shield.service";
import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import swal from 'sweetalert';
import CryptoJS from 'crypto-js';

export default {
  name: "PlanForm",
  components: { PlanEscritorio, PlanMovil, Titulo, NavBar },
  data: () => ({
    secretKey: process.env.VUE_APP_CRYPT,
    userData: {
      name: null,
      surname: null,
      serialNumber: null,
      rut: null,
      typeIsapre: null,
      region: null,
      commune: null,
      comuneCode: null,
      regionCode: null,
      address: null,
      email: null,
      phoneNumber: null,
      couponId: null,
      stepId: null,
      old: null,
      dates: null,
      salud: null,
      questionOne: null,
      questionTwo: null,
      questionThree: null,
      typePlan: null,
      addressNumber: null,
      addressDepto: null,
      sonExist: false,
      arrayTypesBenef: [],
      oldAux: null,
      day: null,
      month: null,
      year: null,
    },
    servicesData: {
      stepId: null,
      contactID: null,
      type: 'update',
    },
    planData: {
      planOutDesc: null,
      setDesc: false,
      valueDesc: 0,
      couponId: null,
      plan: null,
    },
    plans: [],
    plansOutDesc: [],
    stepperColor: '#4F74E3',
    e1: 2,
    loadingPlans: true,
    labelDescuento: '',
    couponRules: [
      (v) => (!!v && v.length <= 50) || "Debe ingresar máximo 50 caracteres",
      (v) => !!v || 'El código de descuento es requerido.',
      (v) => (v && !/\s/.test(v)) || 'El código de descuento no puede contener espacios en blanco.'
    ],
    screen: window.screen.width,
  }),
  async mounted() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    const query = this.$route.query;

    const userDataAux = localStorage.getItem('userData');
    const servicesDataAux = localStorage.getItem('servicesData');
    const planDataAux = localStorage.getItem('planData');

    if (query.stepID) {

      const abandoned = await ShieldService.newGetStepAbandoned(query.stepID);

      this.userData = abandoned.userData;
      this.planData = abandoned.planData;
      this.servicesData.stepId = abandoned._id;
      this.servicesData.type = 'update';

      const userDataLocal = JSON.stringify(this.userData);
      const servicesDataLocal = JSON.stringify(this.servicesData);
      const planDataLocal = JSON.stringify(this.planData);

      const encryptedUserDataLocal = CryptoJS.AES.encrypt(userDataLocal, this.secretKey).toString();
      const encryptedServicesDataLocal = CryptoJS.AES.encrypt(servicesDataLocal, this.secretKey).toString();
      const encryptedPlanDataLocal = CryptoJS.AES.encrypt(planDataLocal, this.secretKey).toString();

      localStorage.setItem('userData', encryptedUserDataLocal);
      localStorage.setItem('servicesData', encryptedServicesDataLocal);
      localStorage.setItem('planData', encryptedPlanDataLocal);

    } else if (userDataAux) {

      const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
      const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
      this.userData = JSON.parse(decryptedDataUser);
      // console.log("userData: ", this.userData);

      const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
      const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
      this.servicesData = JSON.parse(decryptedServicesData);
      // console.log("servicesData: ", this.servicesData);

      if (planDataAux) {
        const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
        const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
        this.planData = JSON.parse(decryptedDataPlan);
        // console.log("planData: ", this.planData);
      }

    }

    if (this.screen > 800) {
      this.labelDescuento = 'labelDescuento'
      this.labeltextDesc = 'descLabel'
    } else {
      this.labelDescuento = 'labelDescuento2'
      this.labeltextDesc = 'descLabelMovil'
    }

    this.getPlans();
    this.getPlansOutDesc();
  },
  methods: {
    deteleSpace() {
      this.planData.couponId = this.planData.couponId.replace(/\s/g, '');
    },
    async SendCouponID() {
      var couponId = this.planData.couponId.trim();
      if (this.$refs.form.validate()) {
        var plansResponseIndividual = await ShieldService.getPlans(
          this.userData.salud,
          'Individual',
          this.userData.old.name,
          couponId,
        );

        var plansResponseIndividualAux = await ShieldService.getPlans(
          this.userData.salud,
          'Individual',
          this.userData.old.name,
          null,
        );

        var plansResponseFamiliar = await ShieldService.getPlans(
          this.userData.salud,
          'Familiar',
          this.userData.old.name,
          couponId,
        );

        if (plansResponseIndividual.status != 'error' && plansResponseFamiliar.status != 'error') {

          this.planData.valueDesc = Math.round(((Number(plansResponseIndividualAux[0].values[0].priceCLP) - Number(plansResponseIndividual[0].values[0].priceCLP)) / Number(plansResponseIndividualAux[0].values[0].priceCLP)) * 100);

          swal({
            title: "¡Código válido!",
            text: "El descuento se ha aplicado exitosamente.",
            icon: "success",
            button: "De acuerdo",
          });

          this.plans = [];
          this.aux = [0];

          this.plans.push(plansResponseIndividual[0]);
          this.plans.push(plansResponseFamiliar[0]);
          this.aux.push(1);

          this.loadingPlans = false;
          this.planData.setDesc = true;
          this.$forceUpdate();
        } else {
          this.planData.couponId = null;
          this.planData.setDesc = false;
          this.planData.valueDesc = 0;
          swal({
            title: "Código no válido",
            text: "Lamentablemente no existen planes asociados a este código de descuento. Intenta con otro código.",
            icon: "info",
            button: "Ok",
          });
        }
      }
    },
    back() {
      this.$router.push({ path: '/og/' })
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    async getPlansOutDesc() {

      this.loadingPlans = true;
      
      var planIndividual = await ShieldService.getPlans(this.userData.salud, 'Individual', this.userData.old.name, null);
      var planFamiliar = await ShieldService.getPlans(this.userData.salud, 'Familiar', this.userData.old.name, null);

      this.plansOutDesc.push(planIndividual[0]);
      this.plansOutDesc.push(planFamiliar[0]);

      this.loadingPlans = false;

    },
    async getPlans() {
      this.loadingPlans = true;
      var planIndividual = await ShieldService.getPlans(this.userData.salud, 'Individual', this.userData.old.name, this.planData.couponId);
      var planFamiliar = await ShieldService.getPlans(this.userData.salud, 'Familiar', this.userData.old.name, this.planData.couponId);
      this.plans.push(planIndividual[0]);
      this.plans.push(planFamiliar[0]);
      this.loadingPlans = false;
      if (this.planData.couponId) {
        this.planData.setDesc = true;
      } else {
        this.planData.setDesc = false;
      }
    },
  },
};
</script>


<style lang="scss">

.descLabel{
  background-color: #F6F6FE;
  width: 223px; 
  height: 25px; 
  margin: auto;
}

.descLabelMovil{
  background-color: #F6F6FE;
  width: 223px; 
  height: 25px; 
  margin: auto;
}

.descText{
  width: 266px;
  height: 33px;
  font-family: 'Helvetica';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #4F74E3;
}

.labelTituloo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: #2D387C;
}

.labelSubtitulo {
  font-family: Helvetica;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #232323;
  opacity: 0.75;
  padding: 2%;
}

.botonAplicar {
  border-radius: 0px 10px 10px 0px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 143.34%;
  text-transform: capitalize;
}

.labelDescuento {
  width: 320px;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.labelDescuento2 {
  min-width: 30%;
  max-width: 72%;
  height: 48px;
  background: #FFFFFF;
  border: 1.5px solid rgba(142, 142, 142, 0.2);
  box-shadow: 0px 1.5px 15px -2.5px rgba(32, 32, 32, 0.08);
  border-radius: 10px 0 0 10px;
  margin: auto;
}

.planContainer {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.formData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>