<template>
    <v-container>

        <v-row justify="center">
            <v-dialog v-model="dialog" width="600px">
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Términos y Condiciones</span>
                    </v-card-title>
                    <v-card-text style="text-align: justify;">
                        Por este acto, y según lo dispuesto en la Ley N° 19.628 sobre Protección de la Vida Privada y
                        sus modificaciones, autorizo expresamente a Southbridge Compañía de Seguros Generales S.A. para
                        que haga tratamiento, almacene, transmita y comunique mis datos personales y la información que
                        le he proporcionado voluntariamente, a su matriz, filiales, sociedades coligadas, empresas
                        relacionadas, sociedades del Grupo Empresarial al que pertenece y terceros prestadores de
                        servicios, estén ubicados dentro o fuera de Chile, con el propósito de que tanto Southbridge
                        Compañía de Seguros Generales S.A. como dichas entidades i) me contacten y pongan a mi
                        disposición, en la forma que estimen pertinente, la más completa información y oferta de sus
                        productos y servicios; ii) procedan al almacenamiento y tratamiento de mis datos personales y de
                        mi información personal para los objetivos del cumplimiento de este Contrato de Seguro; y iii)
                        hagan efectivos el o los convenios que pudieren estar asociados a los seguros que he contratado.
                        Además, por este acto, autorizo expresamente a que cualquier información, comunicación,
                        declaración o notificación que debe efectuar Southbridge Compañía de Seguros Generales S.A. en
                        virtud del presente contrato de seguros sea realizada a través de correo electrónico o de
                        cualquier sistema de transmisión o registro digital de la palabra escrita o verbal o por
                        cualquier otro medio de comunicación fehaciente. El asegurado declara que conoce, acepta y
                        concuerda que cualquier transacción que se realice en el sitio www.sbseguros.cl con su Rut y
                        clave personal, se entenderá y se reputará, para todos los efectos legales, como realizada por
                        él mismo. En tal sentido, el asegurado se hace entera y totalmente responsable del uso y
                        seguridad de su clave, no cabiéndole Southbridge Compañía de Seguros Generales S.A.. ninguna
                        responsabilidad por el uso indebido de la misma por un tercero distinto del asegurado. El
                        asegurable se hace responsable por la veracidad de la información entregada para la contratación
                        del seguro y del uso de las claves o códigos, liberando a Southbridge Compañía de Seguros
                        Generales S.A. de responsabilidad por cualquier mal uso que hagan terceros de la información que
                        Southbridge Compañía de Seguros Generales S.A. entregue exclusivamente a la persona del
                        asegurable.
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="botonCondiciones" style="color: white;" elevation="0" text
                            @click="dialog = false">
                            Aceptar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>

        <br>

        <NavBar :step="6" :userData="userData"></NavBar>

        <Titulo></Titulo>

        <br>
        <br>
        <br v-if="screen < 800">

        <div v-show="showTokuIframe">

            <div id="toku-container">

            </div>

        </div>

        <div v-if="loadingToku == true" class="text-center" style="margin-top:5%">
            <!-- <div v-if="loadingFinal == true" class="text-center" style="margin-top:5%"> -->

            <v-progress-circular :size="300" color="primary" indeterminate></v-progress-circular>

            <br>
            <br>

            {{ cont }}

        </div>

        <div v-else>

            <div v-if="!showTokuIframe" style="max-width: 500px; margin: auto;">

                <h3 class="text-center labelPaso">
                    ¡{{ userData.name }}, ESTAMOS A UN PASO!
                </h3>

                <br>

                <h4 class="text-center labelSubpaso">
                    Antes de contratar te mostramos un resumen de tu Seguro Oncológico
                </h4>

                <v-row>

                    <v-col>

                        <v-card :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }" style="margin-left: 10%"
                            height="500px" max-width="100%">
                            <v-card-text class=" font-weight-medium mt-12 text-center text-subtitle-1 color:primary">
                            </v-card-text>

                            <v-col>
                                <label class="labelEtiquetaResumen">Contratante: </label>

                                <label class="labelEtiqueta">{{ userData.name }} {{ userData.surname }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Rut: </label>

                                <label class="labelEtiqueta">{{ userData.rut }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Teléfono: </label>

                                <label class="labelEtiqueta">{{ userData.phoneNumber }}</label>
                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Email: </label>

                                <label class="labelEtiqueta">{{ userData.email }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Tipo de Plan: </label>

                                <label class="labelEtiqueta">{{ userData.typePlan }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Previsión de Salud: </label>

                                <label class="labelEtiqueta">{{ userData.salud }}</label>

                                <br>
                                <br>

                                <label class="labelEtiquetaResumen">Declaración de Salud: </label>

                                <label class="labelEtiqueta">Ok</label>

                                <br>
                                <br>

                                <div v-if="planData.setDesc" :class="descLabelPrice">
                                    <label> Antes  <span style="text-decoration: line-through;">{{ formatPrice(planData.planOutDesc) }}</span></label>
                                </div>

                                <div :class="rectangulo" style="text-align:center;">

                                    <label class="labelPrecioPlan">{{ formatPrice(planData.plan.values[0].priceCLP)
                                        }}</label>

                                    <label class="labelPrecioUf">/ UF {{ (planData.plan.price).toFixed(2) }}
                                        mensual</label>

                                </div>

                                <div class="lineaIzquierda"></div>

                                <div class="lineaDerecha"></div>

                                <br>
                                <br>

                                <h3 class="text-center labelFecha">*De acuerdo al valor UF del día {{ fechaActual }}
                                </h3>

                                <div v-if="planData.setDesc" class="planOutDesc">
                                    <label>Descuento aplicado</label>
                                </div>

                            </v-col>

                        </v-card>

                        <v-form ref="form" v-model="valid" max-witdh="300px">

                            <v-hover>
                                <v-checkbox :class="vista" :rules="conditionsRules" :color="stepperColor" required>
                                    <template v-slot:label>
                                        <p :class="terminos">Acepto los
                                            <b>
                                                <a style="color:#2D387C" @click="dialog = true">Términos y
                                                    Condiciones</a>
                                            </b>
                                        </p>
                                    </template>
                                </v-checkbox>
                            </v-hover>


                        </v-form>

                        <div class="button-container">
                            <v-btn
                                style="background-color: #2D387C; color: white; border-radius: 12px; width: 300px; height: 120%; margin-left: 5%;"
                                @click="validate()">
                                Ir a pagar
                            </v-btn>
                        </div>

                    </v-col>

                </v-row>

            </div>

            <br>
        </div>

    </v-container>
</template>

<script>
import ShieldService from "../services/shield.service.js";
import Titulo from "../components/Titulo.vue";
import NavBar from "@/components/NavBar.vue";

import CryptoJS from 'crypto-js';

export default {
    data() {
        return {
            secretKey: process.env.VUE_APP_CRYPT,
            personId: '',
            showTokuIframe: false,
            toku: null,
            terminos: null,
            dialog: false,
            name: "Test",
            fechaActual: null,
            cont: "Cargando.  ",
            loading: false,
            loadingToku: false,
            loadingFinal: false,
            loadingPlans: false,
            screen: window.screen.width,
            formatter: new Intl.NumberFormat("es-CL", {
                style: "currency",
                currency: "CLP",
            }),
            stepperColor: '#4F74E3',
            stepId: null,
            edadMayor: null,
            userData: {
                name: null,
                surname: null,
                serialNumber: null,
                rut: null,
                typeIsapre: null,
                region: null,
                commune: null,
                comuneCode: null,
                regionCode: null,
                address: null,
                email: null,
                phoneNumber: null,
                couponId: null,
                stepId: null,
                old: null,
                dates: null,
                salud: null,
                questionOne: null,
                questionTwo: null,
                questionThree: null,
                typePlan: null,
                addressNumber: null,
                addressDepto: null,
                sonExist: false,
                arrayTypesBenef: [],
                oldAux: null,
                day: null,
                month: null,
                year: null,
            },
            servicesData: {
                stepId: null,
                contactID: null,
                type: 'create',
            },
            planData: {
                planOutDesc: null,
                setDesc: false,
                valueDesc: 0,
                couponId: null,
                plan: null,
            },
            benefData: [
                {
                    validRut: false,
                    tipo: null,
                    edad: null,
                    name: '',
                    surname: '',
                    rut: '',
                    days: ['Selecciona el mes'],
                    months: null,
                    years: null,
                    date: null,
                    newRut: '',
                    panel: 0,
                    year: null,
                    month: null,
                    day: null,
                }
            ],
            sonExist: false,
            activeCampaingUse: process.env.VUE_APP_ACTIVE_CAMPAIGN ? process.env.VUE_APP_ACTIVE_CAMPAIGN : 'FALSE',
            conditionsRules: [
                (v) => !!v || "Debe estar de acuerdo con los términos y condiciones",
            ],
        }
    },
    components: { Titulo, NavBar },
    async mounted() {

        document.body.scrollTop = document.documentElement.scrollTop = 0;

        const userDataAux = localStorage.getItem('userData');
        const benefDataAux = localStorage.getItem('benefData');
        const servicesDataAux = localStorage.getItem('servicesData');
        const planDataAux = localStorage.getItem('planData');

        const bytesUserData = CryptoJS.AES.decrypt(userDataAux, this.secretKey);
        const decryptedDataUser = bytesUserData.toString(CryptoJS.enc.Utf8);
        this.userData = JSON.parse(decryptedDataUser);
        // console.log("userData: ", this.userData);

        if (benefDataAux) {
            const bytesBenefData = CryptoJS.AES.decrypt(benefDataAux, this.secretKey);
            const decryptedDataPet = bytesBenefData.toString(CryptoJS.enc.Utf8);
            this.benefData = JSON.parse(decryptedDataPet);
            // console.log("benefData: ", this.benefData);
        }

        const bytesServicesData = CryptoJS.AES.decrypt(servicesDataAux, this.secretKey);
        const decryptedServicesData = bytesServicesData.toString(CryptoJS.enc.Utf8);
        this.servicesData = JSON.parse(decryptedServicesData);
        // console.log("servicesData: ", this.servicesData);

        const bytesPlanData = CryptoJS.AES.decrypt(planDataAux, this.secretKey);
        const decryptedDataPlan = bytesPlanData.toString(CryptoJS.enc.Utf8);
        this.planData = JSON.parse(decryptedDataPlan);
        // console.log("planData: ", this.planData);

        this.calcularFecha()

        if (this.screen > 800) {
            this.vista = 'test'
            this.rectangulo = 'rectangulo'
            this.terminos = 'terminos'
            this.descLabelPrice = 'planOut'
        } else {
            this.vista = 'test2'
            this.rectangulo = 'rectangulo2'
            this.terminos = 'terminos2'
            this.descLabelPrice = 'planOutMovil'
        }
    },
    methods: {
        calcularFecha() {
            var fecha = new Date().toISOString().substr(0, 10).split('-')
            this.fechaActual = fecha[2] + '/' + fecha[1] + '/' + fecha[0]
        },
        formatPrice(number) {
            return this.formatter.format(number);
        },
        back() {
            this.$emit("back");
        },
        async validate() {
            if (this.$refs.form.validate()) {

                // Conectar con SHIELD
                this.loadingToku = true;
                this.loading = true;
                this.loadingFinal = true;

                try {

                    if (this.activeCampaingUse == 'TRUE') {

                        await ShieldService.updateContactActiveCampain(null, null, this.servicesData, 6);
                    }

                    this.cont = "Estamos Procesando tu Solicitud . . ."

                    const res = await ShieldService.createInsurance(
                        this.userData,
                        this.benefData,
                        this.planData.plan,
                        this.servicesData.stepId,
                    );

                    window.location.href = res

                    this.loadingToku = false;

                } catch (error) {
                    this.loadingFinal = false;
                    this.loading = false;
                    this.$router.push({ path: '/og/error' })
                }
            }
        },
    }
}
</script>

<style lang="scss">
.toku-frame {
    border: none;
}

.planOut {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #7474F4;
    margin-left: 38px;
}

.planOutDesc {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #7474F4;
    margin-left: 38px;
}

.planOutMovil {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    color: #7474F4;
    margin-left: 38px;
    margin-top: -7%;
}

.labelFecha {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #232323;
    opacity: 0.75;
    margin-left: 20%;
}

.labelPrecioPlan {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #2D387C;
}

.labelPrecioUf {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #2D387C;
    opacity: 0.8;
}

.botonCondiciones {
    box-sizing: border-box;
    padding: 12px 24px;
    gap: 10px;
    background: #4F74E3;
    border: 2px solid #4F74E3;
    border-radius: 8px;
    margin-top: -6%;
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto;
}

.labelPaso {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #4F74E3;
    text-transform: uppercase;
}

.labelSubpaso {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: #2D387C;
}

.labelEtiquetaResumen {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: black;
}

.labelEtiqueta {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: -0.01em;
    color: black;
}

.terminos {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
}

.terminos2 {
    font-family: Helvetica;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 143.34%;
    letter-spacing: -0.3px;
    color: #232323;
    opacity: 0.8;
    text-align: justify;
    margin-top: 5%;
}

.test {
    margin-left: 25%;
    margin-top: 1%;
}

.test2 {
    margin-left: 15%;
    margin-top: 2%;
}

.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.rectangulo {
    box-sizing: border-box;
    position: absolute;
    height: 30px;
    width: 200px;
    border: 2px solid #2D387C1f;
    border-radius: 5px;
    margin-left: 90px;
    margin-right: 90px;
}

.linea {
    position: absolute;
    top: 80%;
    transform: translateY(-50%);
    border-top: 2px solid #2D387C1f;
    width: calc(24%);
    height: 0;
    background: #2D387C;
    opacity: 0.15;
}

.lineaIzquierda {
    left: 0;
    width: 122px;
}

.lineaDerecha {
    right: 0;
    width: 120px;
}

.rectangulo2 {
    box-sizing: border-box;
    position: absolute;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 231px;
    height: 35px;
    width: 250px;
    border: 2px solid #2D387C1f;
    border-radius: 5px;
}

.step-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
        font-weight: 500;
        text-align: center;
        color: #464e71;
        margin-bottom: 2rem;
    }

    h2 {
        font-weight: 500;
        color: #464e71;
        margin-bottom: 1rem;
    }

    p {
        text-align: center;
    }

    .back-container {
        width: 100%;
        align-items: flex-start;

        .back-button {


            &:hover {
                opacity: 1;
            }
        }
    }

    .button-container {
        text-align: center;
    }

    .forecast-selector {
        text-transform: uppercase;
        font-weight: 600;
        color: #464e71;
    }
}
</style>
